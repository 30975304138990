import React, { CSSProperties } from "react"
import { TickerComponentProps } from "../../types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faLock, faStar } from "@fortawesome/free-solid-svg-icons"
import { useContext } from "react"
import { UserContext } from "../contexts/User"
import Typography from "@mui/joy/Typography"
import Box from "@mui/joy/Box"
import Lock from "@mui/icons-material/Lock"
import Stack from "@mui/joy/Stack"
import Card from "@mui/joy/Card"
import { ListItem } from "@mui/joy"

export const Ticker = (props:TickerComponentProps) => {

    const { ticker, score, handler, type } = props
    const {user} = useContext(UserContext)

    const access:"premium"|"public"|"login" = ticker.access

    var lockType = "none"

    if (access === "premium" && (!user.loggedIn || user.accountType === "free")) {
        lockType = "premium"
    }else if (access === "login" && !user.loggedIn) {
        lockType = "login"
    }else{
        lockType = "none"
    }

    const Stars = () => {

        let starText = ''

        for (let i = 0; i < score; i++) {
            starText += '★'
        }        

        return starText

    }

    const display = ticker.ticker_name !== "*"?`${ticker.ticker_symbol} | ${ticker.ticker_name}`:`${ticker.ticker_symbol}`

    return (
        
        <ListItem onClick={()=>handler(ticker)} sx={{
            display:"flex",
            flexDirection:"row", 
            bgcolor:"transparent", 
            p:1, 
            whiteSpace:"nowrap", 
            width:"100%", 
            justifyContent:"space-between",
            "&:hover": {
                filter: "brightness(1.2)",
                cursor:"pointer"
            }
            }} 
            variant="plain" 
            color={"neutral"}
            >
            <Typography level="body-xs" color={type === "premium"?"warning":"neutral"}>{display}</Typography>
            <Typography level="body-xs" color={type === "premium"?"warning":"neutral"}>{Stars()}</Typography>
            {lockType !== "none"? (
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{position:"absolute", width:"100%", left:0}}>
                    <Typography level="body-xs" startDecorator={<Lock fontSize="small" color={ lockType === "login"?"info":"warning"}/>}>{lockType === "login" ? "Login" : "Premium"}</Typography>
                </Stack>
            ):null}            
        </ListItem>
    )

}