import { createContext, ReactNode, useEffect, useState } from "react";
import { UserType } from "../../types";

export const UserContext = createContext({
    user: {} as UserType,
    oneTapNeeded: false
} );

export const UserProvider = ({ children }: { children: ReactNode }) => {

    const [user, setUser] = useState<UserType>({
        username: "",
        accountType: "free",
        picture: "",
        loggedIn: false,
        expirationDate: null,
        partnerEligible: false,
        stripe_verification_status: "not_started",
        stripeID: ""
    });

    const [oneTapNeeded, setOneTapNeeded] = useState(false);

    const fetchUser = async () => {
        
        const response = await fetch("/api/user");
        const data = await response.json();

        if(data.status === "error"){
            setOneTapNeeded(true);
            return
        }

        const userInfo = data.data;
        setUser({...userInfo, loggedIn: true });
        

    }

    const userEventListener = (event: MessageEvent) => {
            
            if (event.origin === window.location.origin) {
                const { type } = event.data;
                if (type === "auth") {
                    fetchUser();
                }
            }
        
    }

    useEffect(() => {

        fetchUser()
        window.addEventListener("message", userEventListener);

        return () => {
            window.removeEventListener("message", userEventListener);
        }

    }, []);


    return (
        <UserContext.Provider value={{user, oneTapNeeded}}>
            {children}
        </UserContext.Provider>
    );
};