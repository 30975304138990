import { PageProps } from "../../../../types";
import { Eyes } from "../../Eyes";
import { User } from "../../User";
import { ChartContext } from "../../../contexts/Chart";
import ButtonGroup from "@mui/joy/ButtonGroup";
import ToggleButtonGroup from "@mui/joy/ToggleButtonGroup";
import Button from "@mui/joy/Button";
import Container from "@mui/joy/Container";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import { About } from "./About";
import { Pricing } from "./Pricing";
import { useContext } from "react";
import { LayoutContext } from "../../../contexts/Layout";
// import { ButtonGroup, Button } from "@mui/material"
import { useMediaQuery } from "@mui/material";

export const HeaderMenu = () => {

  const { setActivePage, activePage } = useContext(LayoutContext);

  const handleChange = (event: any, value: any) => {
    setActivePage(value);
  };

  return (
    <Tabs
      defaultValue={"Home"}
      sx={{ bgcolor: "transparent", width: "100%" }}
      onChange={handleChange}
      value={activePage}
      size="sm"
    >
      <TabList
        disableUnderline={true}
        sx={(theme) => ({
          p: 0.5,
          gap: 0.5,
          borderRadius: "xl",
        })}
        variant="outlined"
        color="neutral"
        tabFlex={1}
      >
        <Tab disableIndicator value="Home">
          {"Home"}
        </Tab>
        <Tab disableIndicator value="Pricing">
          {"Pricing"}
        </Tab>
        <Tab disableIndicator value="About">
          {"About"}
        </Tab>
      </TabList>
      <About />
      <Pricing isOpen={activePage === "Pricing"} onClose={()=>setActivePage("Home")} />
    </Tabs>
  );
};

export const Header = () => {

  const logoClick = () => {
    window.location.href = "/";
  };

  const isMdUp = useMediaQuery((theme:any) => theme.breakpoints.up("md"));

  return (
    <Grid
      container
      spacing={{ xs: 1, md: 4 }}
      p={1}
      pb={0}
      m={0}
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        xs={8}
        md={3}
        onClick={logoClick}
        direction="row"
        alignItems="center"
        sx={{ display: "flex", "&:hover": { cursor: "pointer" }}}
        gap={1}
      >
        <Box>
          <Typography level={isMdUp?"h2":"h3"} fontFamily={"Major Mono Display"}>AlgoTron</Typography>
        </Box>
        <Eyes />
      </Grid>
      <Grid
        xs={12}
        md
        justifyContent="center"
        alignItems="center"
        sx={{ order: { xs: 2, md: 1 } }}
      >
        <HeaderMenu />
      </Grid>
      <Grid
        xs={4}
        md="auto"
        sx={{ order: { xs: 1, md: 2 } }}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <User />
      </Grid>
    </Grid>
  );
};
