import "./App.css";
import { TickersProvider } from "./contexts/Tickers";
import { ChartProvider } from "./contexts/Chart";
import { Main } from "./components/Main/Main";
import { UserProvider } from "./contexts/User";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LayoutProvider } from "./contexts/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import createTheme from "@mui/material/styles/createTheme";
import useTheme from "@mui/material/styles/useTheme";
import { CssVarsProvider } from "@mui/joy/styles";

function App() {

  return (
    <GoogleOAuthProvider clientId="986720668162-pi7brh4t6kjctqrsecjg7o3vn8k3gulm.apps.googleusercontent.com">
      <CssVarsProvider defaultMode="dark">
        <LayoutProvider>
          <UserProvider>
            <ChartProvider>
              <TickersProvider>
                <BrowserRouter>
                  <Routes>
                    <Route path="*" element={<Main />} />
                  </Routes>
                </BrowserRouter>
              </TickersProvider>
            </ChartProvider>
          </UserProvider>
        </LayoutProvider>
      </CssVarsProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
