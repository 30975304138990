import { PageProps, UserType } from "../../types"
import { UserContext } from "../contexts/User"
import { useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRightToBracket, faBars, faDoorOpen } from "@fortawesome/free-solid-svg-icons"
import Button from "@mui/joy/Button"
import LoginOutlined from "@mui/icons-material/LoginOutlined"
import Avatar from "@mui/joy/Avatar"
import Box from "@mui/joy/Box"
import { Profile } from "./Main/Header/Profile"
import { LayoutContext } from "../contexts/Layout"
import { useMediaQuery } from "@mui/material"

const loginComponent = (setpage:PageProps["setActivePage"], isMdUp:boolean) => {

    
    return (
        <Button size={isMdUp?"md":"sm"} variant="solid" color="primary" sx={{borderRadius:"md"}} onClick={()=>setpage("Login")} startDecorator={<LoginOutlined />}>
            Login
        </Button>

// <div className="main-header-login" onClick={()=>setpage("Login")}>
//     Login
//     <FontAwesomeIcon icon={faArrowRightToBracket} />
// </div>
)

}

const userComponent = (user: UserType, setPage:(page:PageProps["activePage"])=>void) => {
    
    return (
        <Avatar 
        onClick={()=>setPage("Profile")} 
        src={user.picture} 
        alt={user.username}
        sx={{
            '&:hover': {
                cursor: "pointer",
                filter: "brightness(0.8)"
            }
        }} />
    )
    
    
    
}


export const User = () => {
    
    const {user} = useContext(UserContext);
    const {setActivePage} = useContext(LayoutContext);
    const isMdUp = useMediaQuery((theme:any)=>theme.breakpoints.up("md"))
    
    return (
        <Box>
            {user.loggedIn ? userComponent(user, setActivePage) : loginComponent(setActivePage, isMdUp)}
            <Profile />
        </Box>
    )
}