import Stack from "@mui/joy/Stack"
import { CSSProperties, Children, useEffect, useRef, useState } from "react"

interface EyelidProps {
    position: "top" | "bottom",
    blink: boolean,
    thinking?: boolean
}

const Eyelid = (props:EyelidProps) => {

    const { position, blink, thinking } = props

    const classString = "algotron-eyelid-" + position + (blink?" blink":"") + (thinking?" thinking":"")

    return (
        <div className={classString}>

        </div>
    )

}

interface EyeProps {
    blink: boolean,
    eyeColor?: string,
    thinking?: boolean
}

const Eye = (props:EyeProps) => {

    const { blink, eyeColor, thinking } = props

    return (
        <div className="algotron-eye">
            <Eyelid position="top" blink={blink} thinking={thinking} />
            <White eyeColor={eyeColor}/>
            <Pupil />
        </div>
    )

}

interface WhiteProps {
    eyeColor?: string

}

const White = (props:WhiteProps) => {

    const { eyeColor } = props

    const style:CSSProperties = {
        backgroundColor: eyeColor || "white"
    }

    return (

        <div className="algotron-eye-white" style={style}>
        </div>

    )

}

const Pupil = () => {

    return (

        <div className="algotron-eye-pupil">

        </div>

    )

}

interface EyesProps {

    lookAtX?: number,
    lookAtY?: number,
    eyeColor?: string,
    thinking?: boolean

}

export const Eyes = (props:EyesProps) => {

    const [blink, setBlink] = useState(false)
    const blinkTimeout = useRef<NodeJS.Timeout>()

    const { lookAtX, lookAtY, eyeColor, thinking } = props

    useEffect(() => {

        const setTimer = () => {

            const time = Math.random()*5000 + 1000

            blinkTimeout.current = setTimeout(() => {
                setBlink(true)
                // console.log("Blinking")
                setTimeout(() => {
                    setBlink(false)
                    setTimer()
                }, 1000)
            }, time)

        }

        setTimer()

        return () => {
            if(blinkTimeout.current) clearTimeout(blinkTimeout.current)
            
        }

    }, [])


    useEffect(() => {

        const eyes = document.querySelectorAll(".algotron-eye")

        const onMouseMove = (e:MouseEvent) => {

            const x = e.clientX
            const y = e.clientY

            // console.log("Mouse move", x, y)

            eyes.forEach((eye) => {

                const eyePosition = eye.getBoundingClientRect()

                const eyeCenterX = eyePosition.left + eyePosition.width/2
                const eyeCenterY = eyePosition.top + eyePosition.height/2

                const angle = Math.atan2(y - eyeCenterY, x - eyeCenterX)

                const pupil = eye.querySelector(".algotron-eye-pupil") as HTMLElement

                pupil.style.transform = `translate(${Math.cos(angle)*5}px, ${Math.sin(angle)*5}px)`

            })

        }

        window.addEventListener("mousemove", onMouseMove)

        return () => {
            window.removeEventListener("mousemove", onMouseMove)
        }

    }, [])

    useEffect(() => {
            
            if(!lookAtX || !lookAtY) return

            // console.log("Looking at", lookAtX, lookAtY)
    
            const eyes = document.querySelectorAll(".algotron-eye")
    
            eyes.forEach((eye) => {
    
                const eyePosition = eye.getBoundingClientRect()
    
                const eyeCenterX = eyePosition.left + eyePosition.width/2
                const eyeCenterY = eyePosition.top + eyePosition.height/2
    
                const angle = Math.atan2(lookAtY - eyeCenterY, lookAtX - eyeCenterX)
    
                const pupil = eye.querySelector(".algotron-eye-pupil") as HTMLElement
    
                pupil.style.transform = `translate(${Math.cos(angle)*5}px, ${Math.sin(angle)*5}px)`
    
            })



    },[lookAtX, lookAtY])

    return (
        <Stack sx={{
            transform:{xs:"scale(0.8)",md:"scale(1)", width:"fit-content"},
        }} className="algotron-eyes" direction={"row"} >
        <Eye blink={blink} eyeColor={eyeColor} thinking={thinking}/>
        <Eye blink={blink} eyeColor={eyeColor} thinking={thinking}/>
        </Stack>
    );
}