import { useEffect, useRef, useContext, useState } from "react";
import { LayoutContext } from "../../../contexts/Layout";
import { Eyes } from "../../Eyes";
import { Exit } from "../../Exit";
import Box from "@mui/joy/Box";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Stack from "@mui/material/Stack";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Grid from "@mui/joy/Grid";
import Drawer from "@mui/joy/Drawer";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Divider from "@mui/joy/Divider";


export const About = () => {

  const aboutRefPage = useRef<HTMLDivElement>(null);
  // const { setActivePage, activePage } = useContext(LayoutContext);

  // useEffect(() => {

  //     if(aboutRefPage.current) {
  //         aboutRefPage.current.style.opacity = '1'
  //         aboutRefPage.current.style.zIndex = '5'
  //     }

  // },[])

  const {activePage, setActivePage} = useContext(LayoutContext);

  return (
    <Drawer
      open={activePage === "About"}
      onClose={()=>setActivePage("Home")}
      slotProps={{
        content: {
          sx: {
            bgcolor: "transparent",
            boxShadow: "none",
            height: "fit-content",
            maxHeight: "100%",
          },
        },
      }}
      anchor="top"
    >
        <Sheet
          sx={{
            p: { xs: 2, md: 4 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background:"linear-gradient(180deg, rgba(0,0,0,1),transparent)",
          }}
          variant="plain"
          color="neutral"
        >
          <ModalClose />
          <Eyes />
          <Stack sx={{ overflow: "auto",p:1 }} alignItems={"center"} textAlign={"center"} spacing={1}>
            <Typography level="title-lg">
              Meet{" "}
              <Typography color="primary" fontWeight={"lg"}>
                Algotron
              </Typography>{" "}
              - Your Financial Market Research Buddy
            </Typography>
            <Typography level="body-md">
              AlgoTron is your go-to tool for deep financial market research,
              using advanced algorithms and technical analysis. Perfect for
              long-term insights, not quick trades.
            </Typography>
            <Divider orientation="horizontal">
              <Typography level="h4">Core Features</Typography>
            </Divider>
            <List sx={{ alignSelf: "baseline" }}>
              <ListItem >
                <Typography level="body-md">📈 Long-Term Patterns: Spot significant price trends over
                years.</Typography>
              </ListItem>
              <ListItem>
                🧠 Smart Algorithms: High-level analysis cutting through
                market noise.
              </ListItem>
              <ListItem>
                🔍 Deep Insights: Actionable info for a better market
                understanding.
              </ListItem>
              <ListItem>
                🔄 Regular Updates: Stay updated with key trends and patterns.
              </ListItem>
            </List>
            <Divider orientation="horizontal">
              <Typography level="h4">Who Benefits?</Typography>
            </Divider>
            <List sx={{ alignSelf: "baseline" }}>
              <ListItem>🕰️ Long-Term Investors</ListItem>
              <ListItem>📊 Financial Analysts</ListItem>
              <ListItem>🎓 Academic Researchers</ListItem>
            </List>
            <Divider orientation="horizontal">
              <Typography level="h4">Join Us</Typography>
            </Divider>
            <Typography>
              Unlock a deeper understanding of the financial markets with
              AlgoTron! 🚀
            </Typography>
            <Typography level="title-sm">
              Disclaimer: AlgoTron provides research and analysis and does not
              constitute investment advice.
            </Typography>
          </Stack>
        </Sheet>
    </Drawer>
  );
};
