import { createContext, ReactNode, useEffect, useState } from "react";
import { TickerProps } from "../../types";
import testData from "./testTickers.json";

export const TickersContext = createContext<{
    tickers:TickerProps[]}>({
    tickers: []
    });

export const TickersProvider = ({ children }: { children: ReactNode }) => {

    const mode = process.env.REACT_APP_MODE || "PROD";
    const initialTickers: TickerProps[] = mode === "PROD"?[]:testData;

    const [tickers, setTickers] = useState<TickerProps[]>(initialTickers);

    const fetchTickers = async () => {

        const response = await fetch("/api/tickers");
        const data = await response.json();

        if(data.status === "error") return console.error(data.message)

        if(mode !== "TEST") setTickers(data.data);

    }

    const tickerEventListener = (event: MessageEvent) => {

        if (event.origin === window.location.origin) {
            const { type } = event.data;
            if (type === "auth") {
                fetchTickers();
            }
        }

    }


    useEffect(() => {

        fetchTickers();
        window.addEventListener("message", tickerEventListener);

        return () => {
            window.removeEventListener("message", tickerEventListener);
        }

    }, []);


    return (
        
        <TickersContext.Provider value={{tickers}}>
            {children}
        </TickersContext.Provider>
    );
};