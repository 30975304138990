import { faT, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface ExitProps {
    onClick: () => void
}

export const Exit = (props:ExitProps) => {

    return (

        <div className="exit" onClick={props.onClick}>
            <FontAwesomeIcon icon={faTimes} />
        </div>

    )

}