import React, { createContext, useEffect, useState, Dispatch, SetStateAction } from 'react'

import { PageProps } from '../../types'
interface CodeStatus {
    code: string;
    status: 'initial' | 'loading' | 'failure' | 'success';
}

interface LayoutContextProps {
    activePage: PageProps["activePage"];
    setActivePage: (page: PageProps["activePage"]) => void;
    device: "desktop" | "mobile";
    affiliateCode: CodeStatus;
    setAffiliateCode: Dispatch<SetStateAction<CodeStatus>>;
}

export const LayoutContext = createContext<LayoutContextProps>({
    activePage: "Home",
    setActivePage: (page:PageProps["activePage"]) => {},
    device: "desktop",
    affiliateCode: {
        code: '',
        status: 'initial',
    },
    setAffiliateCode: () => {}

})

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {

    const [activePage, setActivePage] = useState<PageProps["activePage"]>("Home")
    const [device, setDevice] = useState<"desktop" | "mobile">(window.innerWidth < window.innerHeight ?"mobile":"desktop")
    const [affiliateCode, setAffiliateCode] = useState<{
        code: string;
        status: 'initial' | 'loading' | 'failure' | 'success';
      }>({
        code: '',
        status: 'initial',
      });

    const checkIfMobile = () => {

        if(window.innerWidth < window.innerHeight){
            setDevice("mobile")
        }else{
            setDevice("desktop")
        }

    }


    useEffect(() => {

        checkIfMobile()
        window.addEventListener("resize", checkIfMobile)

        return () => {
            window.removeEventListener("resize", checkIfMobile)
        }

    },[])

    return (
        <LayoutContext.Provider value={{ activePage, setActivePage, device, affiliateCode, setAffiliateCode }}>
            {children}
        </LayoutContext.Provider>
    )
}


