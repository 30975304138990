import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { UserContext } from "../../../contexts/User"
import { useContext, useState } from "react"
import { faArrowTrendUp, faDoorOpen, faMoneyBillTrendUp, faRocket } from "@fortawesome/free-solid-svg-icons"
import { LayoutContext } from "../../../contexts/Layout"
import { Exit } from "../../Exit"
import { PageProps, UserType } from "../../../../types"
import Avatar from "@mui/joy/Avatar"
import Drawer from "@mui/joy/Drawer"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Sheet from "@mui/joy/Sheet"
import ModalClose from "@mui/joy/ModalClose"
import Logout from "@mui/icons-material/Logout"
import Divider from "@mui/joy/Divider"
import WorkspacePremium from "@mui/icons-material/WorkspacePremium"
import Key from "@mui/icons-material/Key"
import Star from "@mui/icons-material/Star"
import ContentCopy from "@mui/icons-material/ContentCopy"
import Card from "@mui/joy/Card"
import Chip from "@mui/joy/Chip"
import Link from "@mui/joy/Link"
import Groups from "@mui/icons-material/Groups"
import Snackbar from "@mui/joy/Snackbar"
import Tooltip from "@mui/joy/Tooltip"

export const Profile = () => {

    const { user } = useContext(UserContext)
    const { setActivePage, activePage } = useContext(LayoutContext)
    

    const logout = () => {

        fetch("/logout", {}).then(res => {
            if(res.ok) {
                window.location.reload()
            }
        })

    }

    return (
        <Drawer 
        anchor="right" 
        open={activePage === "Profile"} 
        onClose={()=>setActivePage("Home")}
        slotProps={{
            content: {
                sx: {
                bgcolor: "transparent",
                boxShadow: "none",
                height: "100%",
                p:2,
                background:"linear-gradient(270deg, rgba(0,0,0,0.5),transparent)"
            }},
            
            
        }}>
            <Sheet sx={{p:2, borderRadius:"md", height:"100%"}} variant="outlined">
                <Stack sx={{height:"100%", gap:1}} direction={"column"}>
                    <ModalClose />
                    <Stack direction="row" alignItems={"center"} sx={{gap:1}}>
                        <Avatar src={user?.picture} alt={user?.username} />
                        <Typography level="h2">{user?.username}</Typography>
                    </Stack>
                        { user?.accountType === "free" ? 
                        <Card variant="outlined">
                            <Stack direction={{xs:"column",lg:"row"}} gap={1} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography startDecorator={<Key color="warning" />} level="title-sm">Get Full Access</Typography>
                                <Button color="warning" endDecorator={<Star />} onClick={()=>setActivePage("Pricing")}>
                                    Premium
                                </Button>
                            </Stack>
                        </Card>
                        :
                            <PartnerProgram partnerEligible={user?.partnerEligible} stripe_verification_status={user?.stripe_verification_status} setActivePage={setActivePage} stripeID={user?.stripeID} /> 
                        }
                    <Stack sx={{width:"100%", mt:"auto"}} direction={"row"} justifyContent={"flex-end"}>
                        <Button size="sm" endDecorator={<Logout />} onClick={logout} color="danger">
                            Logout
                        </Button>
                    </Stack>
                </Stack>
            </Sheet>

        </Drawer>
    )

}

interface PartnerProgramProps {

    partnerEligible: boolean,
    stripe_verification_status: UserType["stripe_verification_status"],
    setActivePage: (page:PageProps["activePage"])=>void,
    stripeID: UserType["stripeID"]

}

const PartnerProgram = (props:PartnerProgramProps) => {

    const { partnerEligible, stripe_verification_status, setActivePage, stripeID } = props
    const [loading, setLoading] = useState<boolean>(false)
    const [chipTooltipOpen, setChipTooltipOpen] = useState<boolean>(false)
    const [linkTooltipOpen, setLinkTooltipOpen] = useState<boolean>(false)


    const clickHandler = async () => {

        if(loading) return

        if(!partnerEligible){
            setActivePage("Pricing")
        }else{

            const url = !stripeID ? "/api/create_affiliate_account" : stripe_verification_status !== "verified" ? "/api/account_link":"/api/dashboard_link"

            setLoading(true)

            fetch(url, {})
            .then((response)=>response.json())
            .then((json)=>{

                const { url, error } = json;

                setLoading(false)
                
                if(error) {
                    console.error(error)
                    return
                }

                if(url) {
                    window.location.href = url
                }
            })

            
        }
    }

    const onCopyHandler = (value:string|undefined, setState:any) => {

        if(!value) return
        navigator.clipboard.writeText(value)

        setState(true)

        setTimeout(()=>{
            setState(false)
        }, 1500)

    }

    const buttonText = () => {

        if(!stripeID) return "Launch"

        switch(stripe_verification_status){
            case "not_started":
                return "Become Partner"
            case "pending":
                return "Application Pending"
            case "verified":
                return "Open Dashboard"
            case "rejected":
                return "Application Rejected"
            case "incomplete":
                return "Complete Application"
            default:
                return "Launch"
        }

    }

    return (
        <Card variant="plain" sx={{width:"100%", p:0}} >
            { partnerEligible ? 
            <Stack direction={"column"} alignItems={"center"} justifyContent={'space-between'} gap={1}>
                <Card sx={{width:"100%"}}>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography startDecorator={<Groups color="primary" />} level="title-md">Partner Program</Typography>
                        { (stripe_verification_status !== "pending") ? 
                        <Button color={stripe_verification_status==="rejected"?"danger":"primary"} onClick={clickHandler} size="sm">
                            {buttonText()}
                        </Button>
                        : <Typography color="primary" variant="soft" level="title-md">Application Pending</Typography>}
                    </Stack>

                </Card>
                {stripe_verification_status === "verified" ?
                    <Card sx={{width:"100%"}}>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} gap={1}>
                            <Typography level="title-sm">Affiliate Code</Typography>
                            <Tooltip open={chipTooltipOpen} title="Copied!" placement="left">
                                <Chip startDecorator={<ContentCopy/>} onClick={()=>onCopyHandler(stripeID, setChipTooltipOpen)} color="success">{stripeID}</Chip>
                            </Tooltip>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"} gap={1}>
                            <Typography level="title-sm">Affiliate Link</Typography>
                            <Tooltip open={linkTooltipOpen} title="Copied!" placement="left">
                                <Typography level="title-sm" >
                                    <Link onClick={()=>onCopyHandler(`algotron.io/?refer=${stripeID}`, setLinkTooltipOpen)} startDecorator={<ContentCopy />}>algotron.io/?refer={stripeID}</Link>
                                </Typography>
                            </Tooltip>
                        </Stack>
                    </Card>
                :null}
            </Stack>
            : <Typography level="title-md">Partner Program Eligibility: Not Eligible</Typography>}
        </Card>
    )


}