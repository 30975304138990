import { IChartApi, ISeriesApi, Time } from 'lightweight-charts';
import { BandsIndicator } from '../BandsIndicator/Bands';

class TutorialSuite {

    areaSeries: ISeriesApi<"Area">|null = null;
    candleStickSeries: ISeriesApi<"Candlestick">|null = null;
    animationInterval: any;
    pauseTimeout: any;
    abort: boolean = false;
    active: boolean = false;
    chart: IChartApi|null = null;
    data: any;
    chartContainerRef: React.RefObject<HTMLDivElement>|null = null;
    startTimeout: any;
    lineSeriesArray: {LineChart:ISeriesApi<"Line">, data:any}[] = [];

    constructor(){

    }

    pause = async (time:number) => {

        setTimeout(() => {
            return
        }, time);

    }

    setChart = (chart:IChartApi) => {
        this.chart = chart;
        this.abort = false;
    }

    setData = (data:any) => {

        let finalData = data.map((chartData:any) => {
            return {
                priceData: chartData.priceData.map((data:any) => {
                    return {
                        time: data._time as Time,
                        open: data.Open,
                        high: data.High,
                        low: data.Low,
                        close: data.Close,
                        value: data.Close,
                    }
                }),
                lineData: chartData.lineData
            }
        });


        this.data = finalData;
    }

    setChartContainer = (chartContainerRef:React.RefObject<HTMLDivElement>) => {
        this.chartContainerRef = chartContainerRef;
    }

    prepareLines = (data:any) => {

        if(!this.chart) throw new Error("Chart not set");
        if(!data) throw new Error("Data not set");

        const sortedLines = data
        if(!sortedLines) return
        sortedLines.sort((a:any,b:any) => b.score - a.score)

        for(let i = 0; i < sortedLines.length; i++) {

            if(sortedLines[i].type == "horizontal") continue;

            if(
                this.lineSeriesArray.length == 0 || (this.lineSeriesArray[0].data.angle * sortedLines[i].angle < 0 && Math.abs((this.lineSeriesArray[0].data.start_price - sortedLines[i].start_price) / this.lineSeriesArray[0].data.start_price) > 0.2)){
                
                const LineChart = this.chart.addLineSeries({
                    color: 'white',
                    lineWidth: 3,
                    priceLineVisible: false,
                    crosshairMarkerVisible: false,
                }) as ISeriesApi<"Line">;

                const markerArray = sortedLines[i].impact_points.slice(2)

                LineChart.setMarkers(markerArray.map((point:any, index:number) => {

                    return {
                        time: Number(point.time),
                        position: point.impact == "trough" ?'belowBar' : point.impact == "peak"? 'aboveBar' : "inBar",
                        color: point.impact == "trough" ?'blue' : 'purple',
                        shape: point.impact == "trough" ?'arrowUp' : point.impact == "peak"? 'arrowDown' : "circle",
                        id: 'impact-point'
                    }
                }).sort((a:any,b:any) => a.time > b.time ? 1 : -1));

                this.lineSeriesArray.push({LineChart:LineChart, data:sortedLines[i]})
            }

            if(this.lineSeriesArray.length == 2) {
                break
            }
        }

        const horizontalLines = sortedLines.filter((line:any)=> line.type === "horizontal")

        if(horizontalLines.length == 0) return
        // console.log(horizontalLines)

        horizontalLines?.sort((a:any,b:any)=>{
            if (a.score != b.score) {
                return b.score - a.score;
            }

            return Number(a.start_time) - Number(b.start_time);
        })

        const chosenLine = horizontalLines[0];

        const LineChart = this.chart.addLineSeries({
            color: 'transparent',
            lineWidth: 3,
            priceLineVisible: false,
        }) as ISeriesApi<"Line">;

        const markerArray = chosenLine.impact_points.slice(2)

        LineChart.setMarkers(markerArray.map((point:any, index:number) => {

            return {
                time: Number(point.time),
                position: point.impact == "trough" ?'belowBar' : point.impact == "peak"? 'aboveBar' : "inBar",
                color: point.impact == "trough" ?'green' : 'red',
                shape: point.impact == "trough" ?'arrowUp' : point.impact == "peak"? 'arrowDown' : "circle",
                id: 'impact-point'
            }
        }).sort((a:any,b:any) => a.time > b.time ? 1 : -1));

        this.lineSeriesArray.push({LineChart:LineChart, data:chosenLine})

        return 
    }

    animateLine = async (line:ISeriesApi<"Line">, priceData:any) => {

        console.log("lineAnimation")


        return new Promise((resolve, reject) => {

            const intervalMS = 17;
            let counter = 0;

            this.animationInterval = setInterval(() => {

                if(this.abort) return

                if(counter >= priceData.length){
                    clearInterval(this.animationInterval)
                    this.chartContainerRef!.current!.style.opacity = "0";
                    this.pauseTimeout = setTimeout(() => {
                        resolve(true);

                    }, 500)
                    return
                } 

                line.update(priceData[counter])
                counter++

            }, intervalMS)

        })

    }

    showLines = async (priceData:any[], duration:number) => {

        return new Promise(async (resolve, reject) => {
            
            if(!this.chart) throw new Error("Chart not set");
            if(!this.lineSeriesArray) throw new Error("Line Series not set");

            for(const line of this.lineSeriesArray){

                const startIndex = priceData.findIndex((data:any) => Number(data.time) === Number(line.data.start_time))
                if(startIndex == -1) return

                const priceArray = priceData.slice(startIndex)
                const dividePoint = Math.floor(priceArray.length / Math.floor(duration / 16.67))

                line.LineChart.setData([
                    {time:Number(line.data.start_time) as Time, value:Number(line.data.start_price)},
                    {time:Number(line.data.start_time) + 1 as Time, value:Number(line.data.start_price)}
                ])

                const Bands = new BandsIndicator()
                line.LineChart.attachPrimitive(Bands)

                let linePriceData = []
                let linePrice = Number(line.data.start_price)

                for(let i = 1; i < priceArray.length; i++) {

                    linePrice += Number(line.data.angle)

                    if(i % dividePoint == 0){
                        linePriceData.push({
                            time:priceArray[i].time,
                            value:linePrice
                        })
                    }
                }

                await this.animateLine(line.LineChart, linePriceData)

            }

            resolve(true)
    
        })


    }

    startTutorial = async () => {

        if(!this.chart) throw new Error("Chart not set");
        if(this.active) return
        if(this.abort) return
        this.active = true;

        // await this.pause(1000);

        this.areaSeries = this.chart.addAreaSeries({
            topColor: 'rgba(30, 144, 255, 0.5)',
            bottomColor: 'rgba(30, 144, 255, 0)',
            lineColor: 'rgba(30, 144, 255, 0.5)',
            lineWidth: 2,
        })

        this.candleStickSeries = this.chart.addCandlestickSeries({
            upColor: 'rgba(30, 144, 255, 0.8)', // Dodger blue with transparency
            borderUpColor: 'rgba(30, 144, 255, 1)', // Solid dodger blue
            wickUpColor: 'rgba(70, 130, 180, 1)', // Steel blue
            downColor: 'rgba(199, 21, 133, 0.8)', // Medium violet red with transparency
            borderDownColor: 'rgba(199, 21, 133, 1)', // Solid medium violet red
            wickDownColor: 'rgba(148, 0, 211, 1)', // Dark violet
        })

        for (const chartData of this.data){

            console.time("Chart Animation")
            await this.showChart(chartData.priceData);
            console.timeEnd("Chart Animation")
            console.time("Prepare Lines")
            this.prepareLines(chartData.lineData);
            console.timeEnd("Prepare Lines")
            console.time("Show Lines")
            await this.showLines(chartData.priceData, 1000);
            console.timeEnd("Show Lines")
            this.lineSeriesArray.forEach((line:any) => {
                this.chart?.removeSeries(line.LineChart)
            })
            this.lineSeriesArray = [];

        }

    }

    showChart = async (data:any) => {

        if(!this.chart) throw new Error("Chart not set");
        if(this.abort) return

        this.chart.applyOptions({

            rightPriceScale: {
                scaleMargins: {
                    top: 0.8,
                    bottom: 0.1,
                },
                // visible: false
            },
    
        })
    
        
        return new Promise((resolve, reject) => {

            if(!this.areaSeries || !this.candleStickSeries) throw new Error("Series not set");
            if(!this.chartContainerRef) throw new Error("Chart Container not set");
            if(!this.chart) throw new Error("Chart not set")
            
            this.areaSeries.setData(data);
            this.candleStickSeries.setData(data);
            
            this.chart.timeScale().fitContent();
            
            this.chartContainerRef!.current!.style.opacity = "0.7";
            this.pauseTimeout = setTimeout(() => {
            // candleStickSeries.setData(testData)
            
            let scaleNum = 0.8;
            
            this.animationInterval = setInterval(() => {

                if(this.abort) return
                if(!this.chart) throw new Error("Chart not set")
                
                scaleNum *= 0.98;
                if(scaleNum < 0.3){
                        if(!this.chartContainerRef) throw new Error("Chart Container not set");
                        clearInterval(this.animationInterval);
                        
                        setTimeout(() => {
                            resolve(true);
                        },500)
                    } 
        
                    this.chart.applyOptions({
                        rightPriceScale: {
                            scaleMargins: {
                                top: scaleNum,
                                bottom: 0.1,
                            },
                        },
                        
                    })
        
                }, 17);
    
        }, 500);
    
        })
    

    }

    abortAnimation = () => {
        this.abort = true;
        clearInterval(this.animationInterval);
        clearTimeout(this.pauseTimeout);

    }

    clearChart = () => {

        if(this.chartContainerRef && this.chartContainerRef.current) this.chartContainerRef.current.style.opacity = "1"
        try{
            if(this.areaSeries) this.chart?.removeSeries(this.areaSeries);
            if(this.candleStickSeries) this.chart?.removeSeries(this.candleStickSeries);
            this.lineSeriesArray.forEach((line:any) => {
                this.chart?.removeSeries(line.LineChart)
            })
            this.lineSeriesArray = [];
        }catch(e){
            console.log(e)
        }

        this.chart?.timeScale().resetTimeScale();
        this.chart?.applyOptions({
            rightPriceScale: {
                scaleMargins: {
                    top: 0.2,
                    bottom: 0.1,
                },
                visible:true
            },
        
        })

        
    }




}

const Tutorial = new TutorialSuite();

export default Tutorial;