import { ColorType, IChartApi, ISeriesApi, LineData, Time, createChart } from "lightweight-charts"
import { ChartProps, PriceProps } from "../../types"
import { useEffect, useRef, useState, useContext, RefObject, useCallback } from "react"

import { ChartContext } from "../contexts/Chart";
import { Choose } from "./Choose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendDown, faArrowTrendUp, faList, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Eyes } from "./Eyes";
import { Exit } from "./Exit";
import { LayoutContext } from "../contexts/Layout";
import { Legend } from "./Legend";
import { RectangleShape } from "../SquareRenderer/rectangleshape";
import Sheet from "@mui/joy/Sheet";
import LinearProgress from "@mui/joy/LinearProgress";
import Button from "@mui/joy/Button";
import ListIcon from "@mui/icons-material/List";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Close from "@mui/icons-material/Close";
import Card from "@mui/joy/Card";
import { Transition } from "react-transition-group";
import TutorialSuite from "./../scripts/Tutorial"
import Box from "@mui/joy/Box";
import { BandsIndicator } from "../BandsIndicator/Bands";
import { TradingViewWidget } from "./TradingViewWidget";
import MenuIcon from '@mui/icons-material/Menu';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const SummaryClickBox = (props: { setShowSummary: any; }) => {

    const { setShowSummary } = props;

    return (
        <Button size="sm" sx={{width:"fit-content"}} startDecorator={<QueryStatsIcon />} color="neutral" onClick={()=>setShowSummary(true)}>
            <Typography sx={{color:"white"}} level="body-xs">Summary</Typography>
        </Button>
    )

}

const FindingSummary = (props: { LineSeries: RefObject<{LineChart:ISeriesApi<"Line">,data:any}[]>; ChartData: any; setShowSummary: any; }) => {

    const { LineSeries, ChartData, setShowSummary } = props;

    const [fadeIn, setFadeIn] = useState<boolean>(false);
    const sheetRef = useRef<HTMLDivElement>(null);

    
    useEffect(() => {
    
        setTimeout(() => {
            setFadeIn(true);
        }, 100);

    },[])
    
    if(!ChartData) return null;
    if(!LineSeries.current) return null;

    return (
        <Transition in={fadeIn} timeout={10} nodeRef={sheetRef}>
            {(state) => (
                <Sheet 
                    sx={{justifyContent:"center",
                    alignItems:"center",
                    display:"flex",
                    p:1,
                    bgcolor:"transparent",
                    position:"absolute", 
                    top:0,
                    left:0, 
                    transition:"all 500ms",
                    backdropFilter:["entered", "entering"].includes(state)?"blur(5px)":"none",
                    opacity:["entered","entering"].includes(state)?1:0, 
                    height:"100%", 
                    width:"100%", 
                    zIndex:5}}
                    ref={sheetRef}>
                    <Card sx={{borderRadius:"md", width:"fit-content", display:"flex", flexDirection:"column",alignItems:"center"}}>
                        <Eyes/>
                        <Typography level="title-sm">
                            AlgoTron found <Typography color="warning">{LineSeries.current!.length}</Typography> pattern{LineSeries.current!.length>1?"s":""} on the chart.
                        </Typography>
                        <Stack direction={"column"} gap={1}>
                            {LineSeries.current!.map((line, index) => {

                                const lineData = line.LineChart.data() as LineData[];
                                const priceLength = ChartData!.PriceData.length;
                                const lastPriceClose = ChartData!.PriceData[priceLength-1].close!;
                                const lastPriceTime = ChartData!.PriceData[priceLength-1].time!;
                                const lastLineLevel = lineData.find((line) => line.time >= lastPriceTime)!.value;
                                const priceFiveSMA = ChartData!.PriceData.slice(priceLength-5,priceLength).reduce((acc: any,price: { close: any; }) => acc + price.close!,0)/5;
                                // const priceFiveADR = ChartData!.PriceData.slice(priceLength-5,priceLength).reduce((acc: number,price: { high: any; low: any; }) => acc + (price.high! - price.low!),0)/5;

                                const LevelClassification = priceFiveSMA > lastLineLevel ? 'Support' : 'Resistance';
                                // const impactPrice = LevelClassification === "Support" ? lastLineLevel *1.009 : lastLineLevel * 0.991;
                                // const distanceToImpact = LevelClassification === "Support"? Math.max(0,lastPriceClose-impactPrice):Math.max(0,impactPrice-lastPriceClose)

                                return (
                                <Card key={index}>
                                    <Stack direction={"column"} gap={1}>
                                        <Stack>
                                                <Typography color={LevelClassification === "Support"?"success":"danger"}>{LevelClassification}</Typography> 
                                                <Stack direction={"row"} alignItems={"center"} gap={1}>
                                                    <Typography level="body-xs">Probable reaction on impact: </Typography>
                                                    <Chip size="sm" color={LevelClassification === "Support" ? "success":"danger"}>{LevelClassification === "Support"?"Up":"Down"}</Chip>
                                                </Stack>
                                        </Stack>
                                        <Typography level="title-sm">Price Reactions: </Typography>
                                        <Stack direction={"row"}>
                                            <Chip color="success" size="sm" variant="outlined" endDecorator={<ArrowUpward />}>
                                                Up: {line.data.impact_points.slice(2).filter((line:any)=>line.impact === 'trough').length}
                                            </Chip>
                                            <Chip color="danger" size="sm" variant="outlined" endDecorator={<ArrowDownward />}>
                                                Down: {line.data.impact_points.slice(2).filter((line:any)=>line.impact === 'peak').length}
                                            </Chip>
                                            <Chip color="warning" size="sm" variant="outlined" endDecorator={<Close color="error" />}>
                                                Breakouts: {line.data.impact_points.slice(2).filter((point:any) => point.impact === "violation").length}
                                            </Chip>
                                        </Stack>
                                    </Stack>
                                </Card>
                                )
                            })}
                        </Stack>
                    <Exit onClick={()=>setShowSummary(false)}/>
                    </Card>
                </Sheet>

            )}
        </Transition>
    )

}

export const Chart = () => {

    const { loadChart, Ticker, LineInfo } = useContext(ChartContext) as any
    const {device} = useContext(LayoutContext)

    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartWrapperRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const AreaSeries = useRef<ISeriesApi<"Area">>();
    const CandleStickSeries = useRef<ISeriesApi<"Candlestick">>();
    const LineSeries = useRef<{LineChart:ISeriesApi<"Line">,data:any, Rectangle?:RectangleShape}[]>([]);
    const fetchingData = useRef<boolean>(false);
    const [ChartData, setChartData] = useState<{PriceData:PriceProps[],lineData:any[]}>();
    const [showLines, setShowLines] = useState<boolean>(false);
    const TradingStatus = useRef<boolean>();
    const isPainting = useRef<boolean>(false);
    const [showSummary, setShowSummary] = useState<boolean>(false);
    const updateInterval = useRef<NodeJS.Timer>();
    const fetchAbortController = useRef<AbortController>();
    const tickerAbortController = useRef<AbortController>();
    const pauseTimeout = useRef<NodeJS.Timeout>();
    const zoomInterval = useRef<NodeJS.Timer>();
    const RectangleRef = useRef<RectangleShape>();
    const [counter, setCounter] = useState<number>(0);
    const startTutorialTimeout = useRef<NodeJS.Timeout>();
    const [showTickerDrawer, setShowTickerDrawer] = useState<boolean>(false);

    const fetchHistory = async (logicalRange:any) => {

        if(fetchingData.current) return;
        if(!chartRef.current) return;
        if(!logicalRange) return;
        if(!CandleStickSeries.current) return;
        if(CandleStickSeries.current.data().length == 0) return;

        const abortController = new AbortController();
        fetchAbortController.current = abortController;

        if(logicalRange.from < -20){

            const end = CandleStickSeries.current!.data()[0].time as any
            const start = end - 100*86400
            fetchingData.current = true;

            const response = await fetch(`/api/get_history/${Ticker}?start=${start}&end=${end-10}`, {signal:abortController.signal});
            const data = await response.json();

            if(data.status === "error") return console.error(data.message)

            const PriceData:PriceProps[] = data.data.map((price:any) => {
                return {
                    time: price._time,
                    open: price.Open,
                    high: price.High,
                    low: price.Low,
                    close: price.Close,
                    value: price.Close,
                    volume: price.Volume
                }
            });

            CandleStickSeries.current?.setData([...PriceData,...CandleStickSeries.current!.data()]);
            fetchingData.current = false;

        }

    }

    interface OHLC {
        open: number;
        high: number;
        low: number;
        close: number;
        time: Time;
    }

    const extrapolateSeries = () =>{

        LineSeries.current.forEach((line) => {

            const lineData = line.LineChart.data() as LineData[];
            const lastLineItem = lineData[lineData.length - 1];
            const lastLinePrice = lastLineItem.value;
            const lastColor = lastLineItem.color;

            for(let i = 0;i<180;i++){

                const time = Number(lastLineItem.time) + i * 86400;
                const price = lastLinePrice + i * Number(line.data.angle);

                line.LineChart.update(
                    {time: time as Time, value: price, color: lastColor}
                );

                line.Rectangle?.setP2({time:time as Time, price:line.Rectangle.p2.price})

            }

        });

    }


    const showSeries = () => {

        if(!chartRef.current) return;
        if(!ChartData) return;
        if(isPainting.current) return;
        isPainting.current = true;

        chartRef.current.applyOptions({
            rightPriceScale: {
                autoScale: true,
            }
        })

        // console.log('showing series')
        // console.log(LineSeries)

        chartRef.current.timeScale().applyOptions({
            // rightBarStaysOnScroll:true,
            visible:true,
            timeVisible:true,
            secondsVisible:false,
            rightOffset:50
        })

        // chartRef.current.timeScale().scrollToPosition(2, true);

        const volumeData = ChartData.PriceData.map((price) => {
            return {
                time: price.time,
                value: price.volume
            }
        })

        const duration = counter <3 ?10000:5000;
        const intervalDuration = duration / ChartData.PriceData.length;
        let currentIndex = device === "desktop" ? 0 : 20;
        
        const OHLCSeries = chartRef.current.addCandlestickSeries({
            upColor: 'rgba(30, 144, 255, 0.8)', // Dodger blue with transparency
            borderUpColor: 'rgba(30, 144, 255, 1)', // Solid dodger blue
            wickUpColor: 'rgba(70, 130, 180, 1)', // Steel blue
            downColor: 'rgba(199, 21, 133, 0.8)', // Medium violet red with transparency
            borderDownColor: 'rgba(199, 21, 133, 1)', // Solid medium violet red
            wickDownColor: 'rgba(148, 0, 211, 1)', // Dark violet
        });

        const AreaSeriesChart = chartRef.current.addAreaSeries({
            topColor: 'rgba(30, 144, 255, 0)',
            bottomColor: 'rgba(30, 144, 255, 0)',
            lineColor: 'rgba(30, 144, 255, 0)',
            lineWidth: 2,
        })

        
        OHLCSeries.setData([...ChartData.PriceData.slice(0,currentIndex)]);
        // HistogramSeriesChart.setData([...volumeData.slice(0,currentIndex)]);

        const createChart = async () => {

            let intervalID = setInterval(async () => {
                currentIndex += 1;
                
                if(currentIndex >= ChartData.PriceData.length) {
                    clearInterval(intervalID);
                    zoomChart();
                    // setShowLines(true);
                    isPainting.current = false;
                    return;
                }
                const start_time = ChartData.PriceData[currentIndex].time;
                const lastBarPrice = ChartData.PriceData[currentIndex].close
                LineSeries.current.forEach((line) => {
    
                    const lineStartTime = Number(line.data.start_time);
                    
                    const lineStartIndex = ChartData.PriceData.findIndex(bar=>bar.time == lineStartTime);
                    const firstImpactBar = line.data.type == "horizontal" ? line.data.impact_points[1]:line.data.impact_points[2];
                    const lastLinePrice = Number(line.data.start_price) + Number(line.data.angle) * (ChartData.PriceData.length-1-lineStartIndex)
                    
                    if(Number(firstImpactBar.time) == start_time) {

                        if(line.data.type == "horizontal"){
                            const startDataPoint = {
                                time: lineStartTime as Time,
                                price: Number(line.data.start_price)
                            }
                            const endDataPoint = {
                                time: start_time as Time,
                                price: lastLinePrice
                            }
    
                            const Rectangle = new RectangleShape(
                                startDataPoint,
                                endDataPoint,
                                {
                                    showLabels: false,
                                }
                            )

                            line.LineChart.attachPrimitive(Rectangle);
                            line.Rectangle = Rectangle;

                            line.LineChart.setData([
                                {time: lineStartTime as Time, value: Number(line.data.start_price)},
                                {time: Number(firstImpactBar.time) as Time, value: Number(line.data.start_price) + Number(line.data.angle) * (ChartData.PriceData.findIndex(bar=>bar.time == firstImpactBar.time)-lineStartIndex)}
                            ]);

                        }else{
                            line.LineChart.setData([
                                {time: lineStartTime as Time, value: Number(line.data.start_price)},
                                {time: Number(firstImpactBar.time) as Time, value: Number(line.data.start_price) + Number(line.data.angle) * (ChartData.PriceData.findIndex(bar=>bar.time == firstImpactBar.time)-lineStartIndex)}
                            ]);

                            const Bands = new BandsIndicator()
                            line.LineChart.attachPrimitive(Bands)
                        }

                        clearInterval(intervalID);
                        // setEyeColor('orange')
                        
                        // setTimeout(() => {
                        //     setBarPosition(getFirstImpactBarPosition(line.data));
                        // }, 200);
                        
                        let pause = setTimeout(() => {
                            createChart();
                            // setEyeColor('white')
                        }, 1000);

                        pauseTimeout.current = pause;

    
                    } else if (start_time > Number(firstImpactBar.time)) {
                        const lineData = line.LineChart.data() as LineData[];
                        const lastPrice = lineData.length > 0 ? lineData[lineData.length - 1].value + Number(line.data.angle) : Number(line.data.start_price);
                        const lineColor = lastPrice > lastBarPrice! ? 'red' : 'green';
                        // setEyeColor(lineColor)
                        if(line.data.type == "horizontal"){
                            line.Rectangle?.setP2({time:start_time as Time, price:lastLinePrice})
                            line.LineChart.update(
                                {time: start_time as Time, value: lastPrice}
                            );

                            line.Rectangle?.applyOptions({
                                fillColor: lastPrice > lastBarPrice! ? 'rgba(255,0,0,0.4)' : 'rgba(0,255,0,0.4)',
                                labelColor: lastPrice > lastBarPrice! ? 'red' : 'green',
                            })

                            // RectangleRef.current?.setP2({time:start_time as Time, price:lastLinePrice})
                        }else{
                            line.LineChart.update(
                                {time: start_time as Time, value: lastPrice, color: lastPrice > lastBarPrice! ? 'red' : 'green'}
                            );
                        }

                    }
    
                });
    
                OHLCSeries.update(ChartData.PriceData[currentIndex]);
                // HistogramSeriesChart.update(volumeData[currentIndex]);
                chartRef.current!.timeScale().fitContent();
                // AreaSeriesChart.update(data[currentIndex]);
    
            }, intervalDuration);

            updateInterval.current = intervalID;

        }
        chartRef.current!.timeScale().fitContent();
        createChart()

        // OHLCSeries.setMarkers(markers);

        CandleStickSeries.current = OHLCSeries;
        // LineSeries.current = LineChart;

        if(device === "desktop") {
            AreaSeriesChart.setData(ChartData.PriceData)
        }

        AreaSeries.current = AreaSeriesChart;
        // updateChart();

    }

    const PrepareLines = useCallback(() => {

        if(!chartRef.current) return
        if(!ChartData) return

        const sortedLines = ChartData?.lineData

        if(!sortedLines) return

        sortedLines.sort((a:any,b:any) => b.score - a.score)

        for(let i = 0; i < sortedLines.length; i++) {

            if(sortedLines[i].type == "horizontal") continue;

            if(LineSeries.current.length == 0 || LineSeries.current[0].data.angle * sortedLines[i].angle < 0 && Math.abs((LineSeries.current[0].data.start_price - sortedLines[i].start_price) / LineSeries.current[0].data.start_price) > 0.2){
                
                const LineChart = chartRef.current.addLineSeries({
                    color: 'orange',
                    lineWidth: 3,
                    priceLineVisible: false,
                    crosshairMarkerVisible: false,
                }) as ISeriesApi<"Line">;

                const markerArray = sortedLines[i].impact_points.slice(2)

                LineChart.setMarkers(markerArray.map((point:any, index:number) => {

                    return {
                        time: Number(point.time),
                        position: point.impact == "trough" ?'belowBar' : point.impact == "peak"? 'aboveBar' : "inBar",
                        color: point.impact == "trough" ?'green' : 'red',
                        shape: point.impact == "trough" ?'arrowUp' : point.impact == "peak"? 'arrowDown' : "circle",
                        id: 'impact-point'
                    }
                }).sort((a:any,b:any) => a.time > b.time ? 1 : -1));

                LineSeries.current.push({LineChart:LineChart, data:sortedLines[i]})
            }

            if(LineSeries.current.length == 2) {
                break
            }
        }

        const horizontalLines = sortedLines.filter(line=> line.type === "horizontal")

        if(horizontalLines.length == 0) return
        // console.log(horizontalLines)

        horizontalLines?.sort((a,b)=>{
            if (a.score != b.score) {
                return b.score - a.score;
            }

            return Number(a.start_time) - Number(b.start_time);
        })

        const chosenLine = horizontalLines[0];

        const LineChart = chartRef.current.addLineSeries({
            color: 'transparent',
            lineWidth: 3,
            priceLineVisible: false,
        }) as ISeriesApi<"Line">;

        const markerArray = chosenLine.impact_points.slice(2)

        LineChart.setMarkers(markerArray.map((point:any, index:number) => {

            return {
                time: Number(point.time),
                position: point.impact == "trough" ?'belowBar' : point.impact == "peak"? 'aboveBar' : "inBar",
                color: point.impact == "trough" ?'green' : 'red',
                shape: point.impact == "trough" ?'arrowUp' : point.impact == "peak"? 'arrowDown' : "circle",
                id: 'impact-point'
            }
        }).sort((a:any,b:any) => a.time > b.time ? 1 : -1));

        LineSeries.current.push({LineChart:LineChart, data:chosenLine})

        return 

    },[ChartData])

    interface LineInfoProps{
        lineInfo:any;
        lineSeries:ISeriesApi<"Line">;
    }

    const LineInfoElement = (props:LineInfoProps) =>{

        // const [lineVisible, setLineVisible] = useState<boolean>(true);

        const {lineInfo, lineSeries} = props;
        // console.log(lineInfo);

        const impactPointsUp = lineInfo.impact_points.filter((point:any) => point.impact == "trough");
        const impactPointsDown = lineInfo.impact_points.filter((point:any) => point.impact == "peak");
        const violations = lineInfo.impact_points.filter((point:any) => point.impact == "violation");

        const startPrice = lineInfo.start_price
        const angle = lineInfo.angle


        return (
            <div className="line-info">
                {/* <input type="checkbox" name="" id="" defaultChecked={true} onChange={()=>setLineVisible(state=>!state)} /> */}
                <p>Start Price: </p>
                <p>{startPrice}</p>
                <p>Angle: </p>
                <p>{angle}</p>
                <p>Impact Points Up: </p>
                <p>{impactPointsUp.length}</p>
                <FontAwesomeIcon icon = {faArrowTrendUp} color={"green"} className="pulse"/>
                <p>Impact Points Down: </p>
                <p>{impactPointsDown.length}</p>
                <FontAwesomeIcon icon = {faArrowTrendDown} color={"red"} className="pulse"/>
                <p>Violations: </p>
                <p>{violations.length}</p>
                <FontAwesomeIcon icon = {faXmark} color={"purple"}/>
            </div>

        )

    }

    const zoomChart = () => {

        const duration = 500;

        if(!chartRef.current) return;

        const logicalRange = chartRef.current.timeScale().getVisibleLogicalRange()!;
        const logicalRangeDuration = logicalRange.to - logicalRange.from;
        const finalDuration = Math.round(logicalRangeDuration /3)
        const diff = logicalRangeDuration - finalDuration;
        const rate = duration / diff;
        let padding = 0;

        const zoomIntervalObject = setInterval(() => {

            padding += 1;

            if(padding >= diff) {
                clearInterval(zoomIntervalObject);
                setShowLines(true);
                setShowSummary(true);
                extrapolateSeries();
                return;
            }

            const newLogicalRange = {
                from: logicalRange.from + padding,
                to: logicalRange.to
            }

            chartRef.current!.timeScale().setVisibleLogicalRange(newLogicalRange);

        }, rate);

        zoomInterval.current = zoomIntervalObject;

    }

    useEffect(() => {

        // if(chartHolder.current) return

        const chart = createChart(chartContainerRef.current!, {
            layout: {
                background:{
                    type: ColorType.Solid,
                    color: "transparent"
                },
                textColor:"white",
                fontFamily: 'Share Tech Mono',
                
            },
            width:chartContainerRef.current!.clientWidth,
            height:chartContainerRef.current!.clientHeight,
            grid: {
                vertLines: {
                    color: 'rgba(42, 46, 57, 0)',
                },
                horzLines: {
                    color: 'rgba(42, 46, 57, 0)',
                },
            },
            autoSize: true,
            crosshair: {
                mode:0
            }
            
        });

        chartRef.current = chart;
        loadChart(chart, chartContainerRef.current!);
        
        TutorialSuite.setChart(chart);
        TutorialSuite.setChartContainer(chartContainerRef);
        chart.timeScale().fitContent()

        fetch(`${process.env.PUBLIC_URL}/tutorial.json`,{signal:fetchAbortController.current?.signal}).then((response) => response.json()).then((data) => {
            TutorialSuite.setData(data);
            startTutorialTimeout.current = setTimeout(()=>{
                TutorialSuite.startTutorial();
            },3000)

        })

        return () => {
            if(startTutorialTimeout.current) clearTimeout(startTutorialTimeout.current)
            fetchAbortController.current?.abort();
            TutorialSuite.abortAnimation()
            TutorialSuite.clearChart()
            chart.remove();
        }

    }, [])

    useEffect(() => {

        if(Ticker && chartRef.current){

            const controller = new AbortController();
            tickerAbortController.current = controller;
    
            fetch(`/api/prices/${Ticker}`, {signal:controller.signal})
                .then((response) => response.json())
                .then((data) => {
    
                    if (!chartRef.current) return;
    
                    if(data.status === "error") return console.error(data.message)
    
                    data = data.data;
    
                    const PriceData:PriceProps[] = data.priceData.map((price:any) => {
                        return {
                            time: price._time,
                            open: price.Open,
                            high: price.High,
                            low: price.Low,
                            close: price.Close,
                            value: price.Close,
                            volume: price.Volume
                        }
                    });
    
                    // TradingStatus.current = data.isTradingHour;;
                    setChartData({PriceData, lineData:data.lineData!});
                    setCounter(state=>state+1);
                    // updateInterval = updateChart(updateController)
                });
    
            chartRef.current.timeScale().subscribeVisibleLogicalRangeChange(fetchHistory)
        }


        return () => {
            console.log("cleanup")
            if(startTutorialTimeout.current) clearTimeout(startTutorialTimeout.current)
            TutorialSuite.abortAnimation()
            TutorialSuite.clearChart()
            if(tickerAbortController.current)tickerAbortController.current.abort();
            if(fetchAbortController.current) fetchAbortController.current.abort();
            if(pauseTimeout.current) clearTimeout(pauseTimeout.current)
            pauseTimeout.current = undefined;
            if(updateInterval.current) clearInterval(updateInterval.current);
            updateInterval.current = undefined;
            if(zoomInterval.current) clearInterval(zoomInterval.current);
            zoomInterval.current = undefined;
            if(RectangleRef.current) RectangleRef.current = undefined;
            chartRef.current!.timeScale().unsubscribeVisibleLogicalRangeChange(fetchHistory)
            if(AreaSeries.current !== undefined){
                chartRef.current!.removeSeries(AreaSeries.current);
            } 
            // chartRef.current!.removeSeries(HistogramSeries.current!);
            if(CandleStickSeries.current) chartRef.current!.removeSeries(CandleStickSeries.current!);
            if(LineSeries.current) LineSeries.current?.forEach((line) => {

                if(line.LineChart) chartRef.current!.removeSeries(line.LineChart);
            })

            LineSeries.current = [];
            setShowLines(false);
            // setShowTradingStatus(false);
            TradingStatus.current = true;
            chartWrapperRef.current!.style.boxShadow = 'none';
            setShowSummary(false);
            isPainting.current = false;
            
        }

    }, [Ticker])

    useEffect(() => {

        if(!ChartData) return;

        PrepareLines();
        showSeries();

    }, [ChartData])

    // useEffect(() => {

    //     if(!Ticker) return;

    //     const controller = new AbortController();
    //     const interval = setInterval(() => {

    //         if(!TradingStatus){
    //             clearInterval(interval);
    //             return;
    //         }

    //         updateChart(controller);

    //     }, 1000*60);

    //     return () => {
    //         controller.abort();
    //         clearInterval(interval);
    //     }

    // },[Ticker])

    return (
        <Sheet className="main-chart-wrapper" ref={chartWrapperRef} 
            sx={{
                bgcolor:"transparent", 
                borderRadius:"xl", 
                flex:3}} variant="outlined">
            {showSummary && Ticker && <FindingSummary LineSeries={LineSeries} ChartData={ChartData} setShowSummary={setShowSummary} />}
            {/* {Ticker && <div className="glowWrapper"></div>} */}
            {!Ticker && <Choose/>}
            <div className="main-chart-wrapper-info">
                {/* {ShowTradingStatus && Ticker && <TradingStatusElement />} */}
                {showLines && (
                    <Stack gap={1} direction={"row"}>
                        <Button startDecorator={<MenuIcon />} size="sm" onClick={()=>setShowTickerDrawer(true)}>
                            <Typography level="body-xs" textColor={"white"}>Company Info</Typography>
                        </Button>
                        <SummaryClickBox setShowSummary={setShowSummary}/>
                    </Stack>)}
                {device === "desktop" && showLines && Ticker && LineSeries.current.map((lineInfo, index) => <LineInfoElement lineInfo={lineInfo.data} key={index} lineSeries={lineInfo.LineChart} />)}
                {Ticker && <Legend Ticker={Ticker} CandleStickSeries={CandleStickSeries} chartRef={chartRef}/>}
                {Ticker && <TradingViewWidget symbol={Ticker} containerRef={chartWrapperRef} open={showTickerDrawer} closeHandler={setShowTickerDrawer}/>}
            </div>
            <Box ref={chartContainerRef} sx={{
                width:"100%",
                height:"100%",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                position:"relative",
                filter:"drop-shadow(0 0 10px rgba(255, 255, 255, 0.3))",
                transition:"all 500ms"
            }} style={{opacity:0}}>
                {/* {chartLoaded && <Series data={data} lineColor={lineColor} areaTopColor={areaTopColor} areaBottomColor={areaBottomColor} chartRef={chartRef} />} */}
            </Box>

        </Sheet>


    )

}