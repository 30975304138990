import Box from "@mui/joy/Box"
import Sheet from "@mui/joy/Sheet"
import Typography from "@mui/joy/Typography"
import { Transition } from "react-transition-group"
import { useMediaQuery } from "@mui/material"
import { useRef } from "react"


export const Choose = () => {

    const isMobile = useMediaQuery((theme:any) => theme.breakpoints.down("md"))
    const childRef = useRef(null)

    return (
        <Box sx={{position:"absolute", top:0,left:0, zIndex:1, width:"100%", display:"flex",flexDirection:"column",alignItems:{sx:"flex-start",md:"center"}}}>
            <Transition in={true}  timeout={500} nodeRef={childRef} appear >
                {(state:string)=>(
                <Sheet variant="plain" ref={childRef} color="neutral" sx={theme =>({
                    p:2,
                    borderRadius:"md", 
                    bgcolor:`transparent`,
                    textShadow:"0 0 5px black",
                    display:"flex",
                    flexDirection:"column",
                    gap:1,
                    alignItems:{sx:"flex-start",md:"center"},
                    width:"90%"})}>
                    <Typography level="h2" sx={{
                            transition:"all 1s",
                            opacity:state === "entered" ? 1 : 0,
                            transform:state === "entered" ? "translateY(0)" : "translateY(-100px)"
                        }}>Welcome to AlgoTron</Typography>
                    <Typography level="title-sm" sx={{
                        transition:"all 1s",
                        opacity:state === "entered" ? 1 : 0,
                        transform:state === "entered" ? "translateY(0)" : "translateY(-100px)"
                    }}>Click on the tickers {isMobile?"below":"on the left"} to see instruments near key support and resistance levels.</Typography>
                </Sheet>
                )}
            </Transition>
        </Box>
    )
}