import { useEffect, useRef, useContext, useState } from "react"
import { PriceTile } from "../../PriceTile";

import Drawer from "@mui/joy/Drawer";
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Key from "@mui/icons-material/Key";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Info from "@mui/icons-material/Info";
import Modal from "@mui/joy/Modal";
import { Transition } from "react-transition-group";
import Button from "@mui/joy/Button";
import Person from "@mui/icons-material/Person";
interface PricingProps {
    isOpen: boolean;
    onClose: (page: string) => void;
}

export const Pricing = (props:PricingProps) => {
    const isMdUp = useMediaQuery((theme:any) => theme.breakpoints.up("md"))

    const { isOpen, onClose } = props;
    const [PartnerProgramOpen, setPartnerProgramOpen] = useState<boolean>(false)

    return (
        <Drawer open={isOpen} onClose={onClose} anchor={isMdUp?"top":"right"} slotProps={{
            content: {
              sx: {
                bgcolor: 'transparent',
                boxShadow: 'none',
                background:{
                    xs:"linear-gradient(270deg, rgba(0,0,0,0.5), transparent)",
                    md:"linear-gradient(180deg, rgba(0,0,0,0.5), transparent)"
                },
                overflowX:"hidden",
                minWidth:"fit-content",
                ":after": {
                    content: '""',
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bottom: -1,
                    right: "100%",
                    background: "rgba(0,0,0,0.5)",
                    backdropFilter: "blur(5px)",
                    transition: "all 500ms",
                    transform: "scale(0.5)",
                    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
                    transitionDelay: "500ms",
                    },
                
              },
            },
          }}>

            <Sheet sx={{
                display:"flex",
                flexDirection:"column", 
                width:"100%", 
                alignItems:"center", 
                bgcolor:"transparent",
                }} 
                >
                <ModalClose />
                {/* <Eyes /> */}
                <Stack direction={{xs:"column",md:"row"}} spacing={1} p={2}>
                    <PriceTile price={39.99} discountedPrice={29.99} term="1 Month" bestValue={false}>
                        <List>
                            <ListItem>
                                <Typography startDecorator={<Key color="warning" />} level="title-sm">Full Access to the AlgoTron</Typography>
                            </ListItem>
                        </List>
                    </PriceTile>
                    <PriceTile price={139.99} discountedPrice={99.99} term="1 Year" bestValue={true}>
                        <List size="md">
                            <ListItem>
                                <Typography startDecorator={<Key color="warning" />} level="title-sm">Full Access to the AlgoTron</Typography>
                            </ListItem>
                            <ListItem sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%"}}>
                                <Typography startDecorator={<AttachMoney color="success" />} level="title-sm">Partner Program</Typography>
                                <Button color="primary" variant="plain" size="sm" onClick={()=>setPartnerProgramOpen(true)} ><Info /></Button>
                            </ListItem>
                        </List>
                    </PriceTile>
                    <PriceTile price={299} discountedPrice={209}term="3 Years" bestValue={false}>
                        <List>
                            <ListItem>
                                <Typography startDecorator={<Key color="warning" />} level="title-sm">Full Access to the AlgoTron</Typography>
                            </ListItem>
                            <ListItem sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%"}}>
                                <Typography startDecorator={<AttachMoney color="success" />} level="title-sm">Partner Program</Typography>
                                <Button color="primary" variant="plain" size="sm" onClick={()=>setPartnerProgramOpen(true)} ><Info /></Button>
                            </ListItem>
                        </List>
                    </PriceTile>                    
                    {PartnerProgramOpen && <PartnerProgram clickHandler={setPartnerProgramOpen} />}
                </Stack>
            </Sheet>

          </Drawer>

    )

}

interface PartnerProgramProps {
    clickHandler: (state:boolean) => void;
}

const PartnerProgram = (props:PartnerProgramProps) => {

    const { clickHandler } = props;
    const modalRef = useRef(null)

    const imageSource = `${process.env.PUBLIC_URL}/goldmoney.webp`

    return (
        <Transition in={true} timeout={10} nodeRef={modalRef} appear>
            {(state:string)=>(
                <Modal open={!['exited', 'exiting'].includes(state)} 
                onClose={()=>clickHandler(false)}
                ref={modalRef}
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    justifyContent:"center",

                }}
                slotProps={
                    {backdrop:{
                        sx:{
                            backdropFilter:["entered"].includes(state) ? "blur(5px)" : "blur(0px)",
                            transition:"all 500ms",

                        }
                    }}
                }>
                    <Sheet sx={{
                        transition:"all 500ms",
                        opacity:["entered"].includes(state) ? 1 : 0,
                        transform:["entered"].includes(state) ? "translateY(0)" : "translateY(-100%)",
                        display:"flex",
                        flexDirection:"column",
                        alignItems:"center",
                        width:{
                            xs:"90%",
                            md:"50%"
                        },
                        minWidth:"min-content",
                        maxHeight:"70%",
                        p:2,
                        borderRadius:"xl",
                        gap:1,
                        overflowY:"auto",
                            }}
                        variant="outlined"
                        >
                        <img src={imageSource} alt="Partner Program" style={{position:"absolute", top:0,left:0, zIndex:-1, filter:"brightness(0.5)"}}/>
                        <Typography sx={{backdropFilter:"blur(5px)", textShadow:"0 0 5px black", p:1, borderRadius:"xl"}} level="h3" endDecorator={<AttachMoney color="success" />} startDecorator={<AttachMoney color="success" />}>Partner Program</Typography>
                        <Typography sx={{backdropFilter:"blur(5px)", textShadow:"0 0 5px black", p:1, borderRadius:"xl"}} level="title-md">Join our Partner Program when you purchase an annual or 3-year subscription and become eligible to earn revenue by promoting our service. After successful registration, you will receive an affiliate link and code. When new users sign up using your affiliate link or code, you'll earn a share of the revenue.</Typography>
                        <List sx={{backdropFilter:"blur(5px)", textShadow:"0 0 5px black"}}>
                            <ListItem><strong>Earnings:</strong></ListItem>
                            <ListItem><Typography color="success">$10</Typography> for each 1-month subscription.</ListItem>
                            <ListItem><Typography color="success">$20</Typography> for each annual subscription.</ListItem>
                            <ListItem><Typography color="success">$60</Typography> for each 3-year subscription.</ListItem>
                        </List>
                        <Typography sx={{backdropFilter:"blur(5px)", textShadow:"0 0 5px black", p:1, borderRadius:"xl"}} level="title-md">This program is a great opportunity to earn passive income by sharing our service with your network.</Typography>
                        <ModalClose />
                    </Sheet>
                </Modal>
            )}
        </Transition>
    )

}