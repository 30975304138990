import { IChartApi} from 'lightweight-charts';
import { createContext, useEffect, useRef, useState } from 'react';

export const ChartContext = createContext({}) as any;

interface ChartProviderProps {

    children: React.ReactNode;

}

export const ChartProvider = (props:ChartProviderProps) => {

    const {children} = props;
    const chartHolder = useRef<IChartApi>();
    const chartHTMLContainer = useRef<HTMLDivElement>();
    const [Ticker, setTicker] = useState<string>("")
    const [TradingStatus, setTradingStatus] = useState<boolean>(false);
    const [ChartData, setChartData] = useState<any>();


    const loadChart = (chart:IChartApi, chartContainer:HTMLDivElement) => {
        chartHolder.current = chart;
        chartHTMLContainer.current = chartContainer;
    }



    return (
        <ChartContext.Provider value={{chartHolder,loadChart, TradingStatus, ChartData, setTicker, Ticker, chartHTMLContainer}}>
            {children}
        </ChartContext.Provider>
    );
};