import { PriceTileProps } from "../../types"
import { CSSProperties, useContext, useEffect, useState } from "react"
import { UserContext } from "../contexts/User"
import { LayoutContext } from "../contexts/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faStar } from "@fortawesome/free-solid-svg-icons";
import Box from "@mui/material/Box";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import useMediaQuery from "@mui/material/useMediaQuery";
import Info from "@mui/icons-material/Info";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

const affiliateCodeRegex = /^[A-Za-z0-9_]{21}$/

export const PriceTile = (props:PriceTileProps) => {

    const { price, term, children, discountedPrice, bestValue } = props
    const {user} = useContext(UserContext)
    const {setActivePage, affiliateCode, setAffiliateCode} = useContext(LayoutContext)
    const [affiliateField, setAffiliateField] = useState<string>("")
    const isMdUp = useMediaQuery((theme:any) => theme.breakpoints.up("md"))

    const priceMode = affiliateCode.status === "success" ? "discounted" : "standard"

    const onClickHandler = async () => {

        const term_product = term === "1 Month" ? "monthly" : term === "1 Year" ? "one_year" : "three_years"
        const product_full = `${priceMode}_${term_product}`

        if (user.loggedIn) {

            const sessionRequest =  await fetch("/premium", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    subscription: product_full,
                    affiliateCode: priceMode === "discounted" ? affiliateCode.code : undefined
                })
            })

            const result = await sessionRequest.json()

            if (result.status === "error") {
                console.error("Error with subscription")
            } else {
                window.location.href = result.url
            }

        } else {
            setActivePage("Login")
        }

    }

    const checkCodeHandler = async () => {

        setAffiliateCode(state=>({...state, status:"loading"}))

        if(process.env.REACT_APP_MODE === "TEST"){
          
            setTimeout(()=>{
                setAffiliateCode({code:affiliateField, status:Math.random() > 0.5 ? "success":"failure"})
            }, 1000)

        }else{

            if(!affiliateCodeRegex.test(affiliateField)){
                setAffiliateCode({code:affiliateField, status:"failure"})
                return
            }

            const response = await fetch("/api/verify_code", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ code:affiliateField })
            })

            const data = await response.json()

            if(data.status === "error"){
                setAffiliateCode({code:affiliateField, status:"failure"})
            }else{
                setAffiliateCode({code:affiliateField, status:"success"})
            }

        }


    }

    return (
        <Card size={isMdUp?"md":"sm"} sx={{
            background: bestValue?"linear-gradient(0deg, #000084, black, #5a005a);":"",
            borderColor: bestValue?"gray":"",
        }}>

            <Typography level="title-lg">{term}</Typography>

            {affiliateCode.status === "success" ? (
                <Typography level="title-lg" color="success" sx={{width:"fit-content"}}>
                {discountedPrice} ${' '}
                 <Typography level="body-xs" sx={{textDecoration:"line-through", color:"gray"}}>{price}</Typography>
                </Typography>    
            ):(
                <Typography level="title-md" sx={{width:"fit-content"}}>
                    {price} $ 
                </Typography>

            )}
            
            <Stack direction="column" spacing={0}>
                {affiliateCode.status === "success" ? (
                    <Chip color="success">Promo Code Activated</Chip>
                ):(
                    <FormControl error={affiliateCode.status === "failure" ? true:false}>
                        <Typography endDecorator={<InfoOutlined />} color="success" level="body-sm">{discountedPrice} $ with promo code</Typography>
                        <Input onFocus={()=>setAffiliateCode(state=>({...state,status:"initial"}))} value={affiliateField}  onChange={(e)=>setAffiliateField(e.target.value)} name="code" endDecorator={<Button loading={affiliateCode.status === "loading"?true:false} onClick={()=>checkCodeHandler()} size="sm" >Apply</Button>} size="sm" placeholder="Enter code" variant="outlined"/>
                        {affiliateCode.status === "failure" && <FormHelperText >
                            <InfoOutlined />
                            {' '}Invalid Code
                        </FormHelperText>}                    
                    </FormControl>
                )}
            </Stack>
            
            <Box>
                {children}
            </Box>
            <Button onClick={onClickHandler}>
                Buy Now
            </Button>
        </Card>
    )

}