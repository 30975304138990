import { LinearProgress } from "@mui/joy";
import { ISeriesApi } from "lightweight-charts";
import { RefObject, useEffect, useState } from "react";

const getLastBar = (series:ISeriesApi<"Candlestick">)=> {
    // console.log(series);
    return series.data()[series.data().length - 1];
}

export const Legend = (props: { Ticker: any; CandleStickSeries: any; chartRef:any }) => {

    // console.log(eyeColor)

    const { Ticker, CandleStickSeries, chartRef} = props;
    const [PriceDate, setPriceDate] = useState({price:0, date:"none"});

    const updateLegend = (param:any) => {

        if(!CandleStickSeries.current) return;

        const validCrosshairPoint = !(
            param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
        );

        const bar = validCrosshairPoint ? param.seriesData.get(CandleStickSeries.current) : getLastBar(CandleStickSeries.current);

        if(!bar) return;

        const time = bar.time;
        // console.log(bar);
        const price = bar.value !== undefined ? bar.value : bar.close;
        const formattedPrice = price.toFixed(2);
        
        // if(!validCrosshairPoint && isPainting.current){
        //     setBarPosition({x:xPosition + chartContainerRef.current!.getBoundingClientRect().left, y:yPosition+chartContainerRef.current!.getBoundingClientRect().top});
        // }
        
        setPriceDate({price:formattedPrice, date:new Date(time*1000).toISOString().split('T')[0]});
    };

    useEffect(() => {

        if(chartRef.current){
            chartRef.current.subscribeCrosshairMove(updateLegend);
        }

        return () => {
            if(chartRef.current){
                chartRef.current.unsubscribeCrosshairMove(updateLegend);
            }
        }

    }, []);

    return (
        <div className="legend">
            <p>{Ticker}</p>
            {PriceDate.price!=0 && <p>Price: {PriceDate.price}</p>}
            {PriceDate.date != "none" && <p>Date: {PriceDate.date}</p>}
            {/* {!showLines &&<Eyes lookAtX={!showLines ? barPosition.x:undefined} lookAtY={!showLines? barPosition.y:undefined} eyeColor={eyeColor} thinking={true}/>} */}
        </div>
    )

}