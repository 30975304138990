import { GoogleLogin } from "@react-oauth/google";
import { useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../contexts/User";
import { LayoutContext } from "../../../contexts/Layout";
import { Exit } from "../../Exit";
import Stack from "@mui/joy/Stack";
import Drawer from "@mui/joy/Drawer";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import { Typography } from "@mui/joy";

export const Login = () => {

    const { oneTapNeeded } = useContext(UserContext);
    const loginPageRef = useRef<HTMLDivElement>(null);

    const { setActivePage, activePage } = useContext(LayoutContext);

    const openLoginWindow = () => {

        const loginWindow = window.open("auth/google", "Google Login", "width=400,height=400,toolbar=no,location=no,menubar=no, status=no,scrollbars=yes,resizable=yes,top=200,left=200, copyhistory=no");
        setActivePage("Home");
        loginWindow?.focus();

    }


    const handleCredentialResponse = async (credentialResponse: any) => {

        const response = await fetch("/verify-google-token", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ token: credentialResponse.credential })
        });

        const data = await response.json();

        if(data.status === "error") return

        setActivePage("Home");

        window.postMessage({ type: "auth" }, window.location.origin);
        
    }

    return (
        <Drawer open={activePage === "Login"} onClose={()=>setActivePage("Home")} anchor="right" slotProps={{
            content: {
              sx: {
                bgcolor: 'transparent',
                boxShadow: 'none',
              },
            },
          }}>
            <Sheet sx={{background:"linear-gradient(90deg,transparent,rgba(0,0,0,0.5))",bgcolor:"transparent",gap:1,p:1,display:"flex", flexDirection:"column", alignItems:"center",justifyContent:"center", height:"100%"}} variant="plain">
                <Typography level="h2" fontFamily={"Major Mono Display"}>AlgoTron</Typography>
                <Typography level="title-md" >Use With</Typography>
                <Stack>
                <GoogleLogin
                    onSuccess={handleCredentialResponse}
                    onError={() => {
                    }}
                    useOneTap={oneTapNeeded}
                    auto_select={true}
                    text={"continue_with"}
                />
                </Stack>
                <ModalClose onClick={()=>setActivePage("Home")} />
            </Sheet>

        </Drawer>
    )

}