import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TickerProps, UserType } from "../../types"
import { Ticker } from "./Ticker"
import { ChartContext } from "../contexts/Chart";
import { useContext } from "react";
import { LayoutContext } from "../contexts/Layout";
import { UserContext } from "../contexts/User";
import { FixedSizeList as FixList } from 'react-window';
import Stack from "@mui/joy/Stack";
import KeyboardDoubleArrowDownSharp from "@mui/icons-material/KeyboardDoubleArrowDownSharp";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import { memo } from "react";
import Divider from "@mui/joy/Divider";
import Input from "@mui/joy/Input";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import Tab from "@mui/joy/Tab";
import Star from "@mui/icons-material/Star";
import { TickersContext } from "../contexts/Tickers";
import SearchIcon from '@mui/icons-material/Search';
import { Chip } from "@mui/joy";

interface TickerSection {
    tickers: TickerProps[];
    device:"desktop"|"mobile"
}

interface TickerListProps {

    type:"public"|"premium";
    height:number;
    tickers:TickerProps[];
    beta:number;

}


const TickerList = memo((props:TickerListProps) => {

    const {user} = useContext(UserContext)
    const {setActivePage} = useContext(LayoutContext)
    const {setTicker} = useContext(ChartContext) as any

    const {type,height,tickers, beta} = props


    const tickerHandler = (ticker:any) => {

        const lockType = !user.loggedIn && ticker.access !== "public" ? "login": user.accountType === "free" && ticker.access === "premium" ? "premium" : "none"

        console.log("Lock Type", lockType)
        switch(lockType) {
            case "login":
                setActivePage("Login")
                break;
            case "premium":
                setActivePage("Pricing")
                break;
            default:
                setTicker(ticker.ticker_symbol)
                break;
        }

    }

    const TickerComponents = useMemo(() => {
        const components = tickers.map((ticker:TickerProps, index:number) => (
            <Ticker key={ticker.ticker_symbol + index} ticker={ticker} score={Math.round(ticker.score * beta)} handler={tickerHandler} type={type}/>
        ))

        return components

    }, [tickers])

    const wrapHeight = height
            
        return (
            <FixList itemCount={TickerComponents.length} height={wrapHeight} itemSize={40} width="100%">
                {({ index, style }) => (
                    <div style={{...style, padding:"0 0.5em"}}>
                        <Divider />
                        {TickerComponents[index]}
                    </div>
                )}
            </FixList>
        )

})

const checkTicker = (ticker:TickerProps, searchField:string) => {
    
        if(ticker.ticker_symbol.startsWith(searchField)) return true
        if(ticker.ticker_name.toUpperCase().startsWith(searchField)) return true

        if(ticker.ticker_symbol.startsWith(`${searchField[0]} *`)) return true
    
        return false
    
    
}

export const Tickers = () => {

    const {device} = useContext(LayoutContext)
    const {tickers} = useContext(TickersContext) as any
    const tabPanelRef = useRef<HTMLDivElement>(null)
    const [tabPanelHeight, setTabPanelHeight] = useState<number>(0)
    const [searchField, setSearchField] = useState<string>("")
    const [tickerCategories, setTickerCategories] = useState<{
        public:TickerProps[],
        premium:TickerProps[]
    }>({
        public:tickers.filter((ticker:TickerProps) => ticker.category === "public"),
        premium:tickers.filter((ticker:TickerProps) => ticker.category === "premium")
    })
    const [beta, setBeta] = useState<number>(0)

    useEffect(() => {

            setTimeout(() => {

                if(!tabPanelRef.current) return

                setTabPanelHeight(tabPanelRef.current.clientHeight)
                console.log("Tab Panel Height", tabPanelRef.current.offsetHeight)
            }, 500)

    }, [device])

    useEffect(() => {

        setTickerCategories({
            public:tickers.filter((ticker:TickerProps) => ticker.category === "public" && checkTicker(ticker, searchField)),
            premium:tickers.filter((ticker:TickerProps) => ticker.category === "premium" && checkTicker(ticker, searchField))
        })

    }, [searchField, tickers])

    useEffect(() => {

        const HighScore = Math.max(...tickers.map((ticker:TickerProps) => ticker.score))

        setBeta(5/HighScore)

    }, [tickers])
    

    return (
        <Stack height={"100%"} gap={1}>
            <Input 
                startDecorator={<SearchIcon />} 
                size="sm" 
                placeholder="Search Tickers" 
                value={searchField}
                onChange={(e) => setSearchField(e.target.value.toUpperCase())}
                sx={{width:"100%", 
                bgcolor:"transparent", 
                color:"neutral", 
                borderRadius:"lg", 
                boxShadow:"none"}}/>
            <Tabs sx={{width:"100%", bgcolor:"transparent", height:"100%", overflow:"hidden", gap:1}} variant="plain" defaultValue={"Standard"} size={"sm"}>
                <TabList tabFlex={1} sx={{p:0.5, gap:0.5, borderRadius:"xl", boxShadow:"none"}} variant="outlined" size="sm">
                    <Tab disableIndicator value="Standard" variant="plain" sx={{borderRadius:"lg"}}>
                        <Typography level="title-sm">Standard</Typography>
                        <Chip size="sm" color="neutral" variant="outlined">{tickerCategories.public.length}</Chip>
                    </Tab>
                    <Tab disableIndicator value="Premium" variant="plain" color="warning" sx={{borderRadius:"lg"}}>
                        <Typography level="title-sm" color="warning">Premium</Typography>
                        <Chip size="sm" color="warning" variant="outlined">{tickerCategories.premium.length}</Chip>
                        <Star />
                    </Tab>
                </TabList>
                <TabPanel value="Standard" sx={{p:0, height:"100%"}} ref={tabPanelRef}>
                    <TickerList type="public" height={tabPanelHeight} tickers={tickerCategories.public} beta={beta}/>
                </TabPanel>
                <TabPanel value="Premium" sx={{p:0}} color="warning">
                    <TickerList type="premium" height={tabPanelHeight} tickers={tickerCategories.premium} beta={beta}/>
                </TabPanel>
            </Tabs>
        </Stack>
    )

}

export default memo(Tickers)