import { memo, useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { TickersContext } from "../../contexts/Tickers";
import { Chart } from "../Chart";
import { Header } from "./Header/Header";
// import { About } from "./Header/About";
import { Login } from "./Header/Login";
import Tickers from "../Tickers";
import { LayoutContext } from "../../contexts/Layout";
import { useLocation, useParams } from "react-router-dom";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";

const InfoComponent = (props: infoComponentProps) => {
  const { info } = props;

  return <div className="main-info">{info}</div>;
};

export const Main = () => {
  const location = useLocation();
  const tickers = useContext(TickersContext);
  const { setActivePage, device, setAffiliateCode } =
    useContext(LayoutContext);
  const [info, setInfo] = useState<string>("");


  useEffect(() => {
    if (location.search) {
      const affiliateCode = new URLSearchParams(location.search).get("refer");
      if (affiliateCode) {
        setAffiliateCode({
          code: affiliateCode,
          status: "success"
        });
      }
    }

    if (location.pathname.includes("/refresh")) {
      setInfo("Link has expired. Please try again");
      setTimeout(() => {
        setInfo("");
        setActivePage("Profile");
      }, 2000);
    }

    if (location.pathname.includes("/return")) {
      setInfo(
        "Thank you for starting the onboarding process. You can check status in your profile section"
      );

      setTimeout(() => {
        setInfo("");
      }, 5000);
    }
  }, [location]);

  const getLastValidUTCDate = () => {
    const now = new Date();
    const utcDay = now.getUTCDay();

    let daysToSubtract;

    switch (utcDay) {
      case 0: // Sunday
        daysToSubtract = 2; // Go back to Friday
        break;
      case 6: // Saturday
        daysToSubtract = 1; // Go back to Friday
        break;
      default: // Monday to Friday
        daysToSubtract = 0;
        break;
    }

    // Subtract the necessary number of days
    now.setUTCDate(now.getUTCDate() - daysToSubtract);

    return now.toUTCString().split(" ").slice(0, 4).join(" ");
  };

  return (
    <Stack sx={{ 
        position:"absolute", 
        width:"100%", 
        height:"100%",
        background:"linear-gradient(180deg, rgba(0,0,0,0.7), transparent)", 
        overflow:"hidden"}}
        direction={"column"}
        m={0}
        key={"stack"}>
      <Header />
      {info && <InfoComponent info={info} />}
      <Login />
      <Stack
        direction={{"xs":"column-reverse","md":"row"}}
        sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            overflow: "hidden",
            px: 1,
            pb:1,
            background: {
                xs: "linear-gradient(90deg, rgba(0,0,0,0.7), transparent 50%)",
                md: "linear-gradient(90deg, rgba(0,0,0,0.7), transparent 30%)"
            }
        }}
        spacing={{md:2,xs:1}}
      >
        <Sheet
          sx={{
            p: 1,
            borderRadius: "xl",
            display: "flex",
            flexDirection: "column",
            flex: {xs:2,md:1},
            bgcolor: "transparent",
            gap: 1,
            overflow: "hidden",
          }}
          variant="outlined"
          
          
        >
          <Stack
            alignItems="center"
            direction={"row"}
            justifyContent="center"
            gap={1}
          >
            <Typography
              level="title-sm"
            >
              Top Market Trends
            </Typography>
            <Typography level="body-xs">{getLastValidUTCDate()}</Typography>
          </Stack>
          <Box sx={{ overflow: "auto", height: "100%"}}>
            <Tickers />
          </Box>
        </Sheet>
        <Chart />
      </Stack>
      <div className="footer">
        <p>AlgoTron 2024</p>
        <a href="/privacy">Privacy</a>
        <a href="/terms">Terms</a>
        <a href="/disclaimer">Disclaimer</a>
      </div>
    </Stack>
  );
};

interface infoComponentProps {
  info: string;
}

export default memo(Main);

