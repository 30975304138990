import Typography from "@mui/joy/Typography"
import Sheet from "@mui/joy/Sheet"
import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Drawer from "@mui/joy/Drawer"
import { useEffect, useRef, useState } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface TradingViewWidgetProps {

    symbol: string,
    containerRef: React.RefObject<HTMLDivElement>,
    open: boolean,
    closeHandler: (state:boolean) => void

}

export const TradingViewWidget = (props:TradingViewWidgetProps) => {

    const { symbol, containerRef, open, closeHandler } = props
    const widgetRef = useRef<HTMLScriptElement>()
    const widgetContainerRef = useRef<HTMLDivElement>()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const launchTimeout = useRef<NodeJS.Timeout>()
    const widgetContainerProfileRef = useRef<HTMLDivElement>()
    const widgetContainerFundamentalsRef = useRef<HTMLDivElement>()
    const isMdUp = useMediaQuery((theme:any)=>theme.breakpoints.up("md"))

    useEffect(() => {
            
            if(containerRef.current && symbol){

                launchTimeout.current = setTimeout(()=>{
                    const script = document.createElement("script")
                    script.type = "text/javascript"
                    script.src = `https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js`
                    script.async = true
                    script.innerHTML = JSON.stringify({
                        "symbol": symbol,
                        "width": "100%",
                        "locale": "en",
                        "colorTheme": "dark",
                        "isTransparent": true,
                    })
    
                    widgetRef.current = script
        
                    widgetContainerRef.current?.appendChild(script)

                    const scriptProfile = document.createElement("script")
                    scriptProfile.type = "text/javascript"
                    scriptProfile.src = `https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js`
                    scriptProfile.async = true
                    scriptProfile.innerHTML = JSON.stringify({
                        "symbol": symbol,
                        "width": "100%",
                        "height": "50%",
                        "locale": "en",
                        "colorTheme": "dark",
                        "isTransparent": true,
                    })

                    widgetContainerProfileRef.current?.appendChild(scriptProfile)

                    const scriptFundamentals = document.createElement("script")
                    scriptFundamentals.type = "text/javascript"
                    scriptFundamentals.src = `https://s3.tradingview.com/external-embedding/embed-widget-financials.js`
                    scriptFundamentals.async = true
                    scriptFundamentals.innerHTML = JSON.stringify({
                        "symbol": symbol,
                        "width": "100%",
                        "height": isMdUp ? "50%":"100%",
                        "locale": "en",
                        "colorTheme": "dark",
                        "isTransparent": true,
                        "displayMode": "adaptive",
                    })

                    widgetContainerFundamentalsRef.current?.appendChild(scriptFundamentals)

                }, 1000)



            }


            return () => {
                clearTimeout(launchTimeout.current)
                widgetRef.current?.remove()
                // widgetRef.current = undefined
                if(widgetContainerRef.current){
                    widgetContainerRef.current.innerHTML = '<div className="tradingview-widget-container__widget"></div>'
                }
                if(widgetContainerProfileRef.current){
                    widgetContainerProfileRef.current.innerHTML = '<div className="tradingview-widget-container__widget"></div>'
                }
                if(widgetContainerFundamentalsRef.current){
                    widgetContainerFundamentalsRef.current.innerHTML = '<div className="tradingview-widget-container__widget"></div>'
                }
            }
    
    }, [symbol])

    const LaunchButton = () => {

        return (
            <Button color="neutral" variant="solid" size="sm" onClick={()=>closeHandler(false)} sx={{position:"absolute", left:"100%", height:"fit-content"}}>
                <Typography startDecorator={<ArrowBackIosIcon />} level="body-xs" textColor={"white"}>{""}</Typography>
            </Button>
        )

    }



    return (
            <Drawer onClose={()=>closeHandler(false)} open={open} anchor="left" container={()=>containerRef.current} sx={{position:"absolute"}} slotProps={{
                content:{
                    sx:{
                        position:"absolute",
                        boxShadow:"none",
                        height:"100%",
                        width:"fit-content",
                        overflow:"visible",
                        p:1,
                        background:"linear-gradient(90deg, black, transparent)",
                        bgcolor:"transparent",
                        minWidth:"50%"
                    }
                },
                backdrop:{
                    sx:{
                        position:"absolute"
                    }
                
                }
            }}>
                <Sheet sx={{overflow:"auto", height:"100%", width:"100%", bgcolor:"transparent", p:1}}>
                    <Box className="tradingview-widget-container" ref={widgetContainerRef} sx={{height:"100%", overflow:"auto"}}>
                        <div className="tradingview-widget-container__widget"></div>
                        
                    </Box>
                    <Box className="tradingview-widget-container" ref={widgetContainerProfileRef} sx={{height:"100%", overflow:"auto"}}>
                        <div className="tradingview-widget-container__widget"></div>
                        
                    </Box>
                    <Box className="tradingview-widget-container" ref={widgetContainerFundamentalsRef} sx={{height:"100%", overflow:"auto"}}>
                        <div className="tradingview-widget-container__widget"></div>
                        
                    </Box>
                </Sheet>
                {LaunchButton()}
            </Drawer>
    )

}